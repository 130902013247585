import { useRef, useEffect, memo } from "react";
import { gsap } from "gsap";
import st from "../../raffle.module.scss";
import SnowFlake from "../../assets/snow-flake";

const SnowingBackground = ({ snowflakeCount = 25, className }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const snowflakes = [];
    const tweens = [];

    const createSnowflake = () => {
      const snowflake = document.createElement("div");
      snowflake.classList.add(st["snowflake"]);

      // Random properties
      const size = Math.random() * 20 + 10; // Size between 10px and 30px
      const left = Math.random() * 100; // Left position between 0% and 100%
      const opacity = Math.random() * 0.5 + 0.5; // Opacity between 0.5 and 1
      const duration = Math.random() * 10 + 5; // Duration between 5s and 15s
      const delay = Math.random() * 15; // Delay between 0s and 15s
      const drift = Math.random() * 50 + 25; // Drift to left between 25px and 75px

      // Apply styles
      snowflake.style.position = "absolute";
      snowflake.style.top = `-${size}px`; // Start above the container
      snowflake.style.left = `${left}%`;
      snowflake.style.width = `${size}px`;
      snowflake.style.height = `${size}px`;
      snowflake.style.opacity = opacity;
      snowflake.style.pointerEvents = "none";

      // Append SVG content
      snowflake.innerHTML = SnowFlake(); // Assuming SnowFlake returns SVG markup as a string

      container.appendChild(snowflake);
      snowflakes.push(snowflake);

      // Animate the snowflake
      const tween = gsap.to(snowflake, {
        y: container.offsetHeight + size, // Move to bottom of the container
        x: `-=${drift}`, // Drift slightly left
        ease: "none",
        duration: duration,
        delay: delay, // Random delay
        onComplete: () => {
          container.removeChild(snowflake);
          snowflakes.splice(snowflakes.indexOf(snowflake), 1);
          tweens.splice(tweens.indexOf(tween), 1);
          createSnowflake(); // Create a new snowflake
        },
      });

      tweens.push(tween);
    };

    // Create initial snowflakes
    for (let i = 0; i < snowflakeCount; i++) {
      createSnowflake();
    }

    // Cleanup function
    return () => {
      // Kill all tweens
      tweens.forEach((tween) => {
        tween.kill();
      });
      // Remove all snowflakes from the container
      snowflakes.forEach((snowflake) => {
        if (container.contains(snowflake)) {
          container.removeChild(snowflake);
        }
      });
    };
  }, [snowflakeCount]);

  return (
    <div
      className={`${st["snowing-background-container"]} ${className}`}
      ref={containerRef}
    ></div>
  );
};

export default memo(SnowingBackground);
