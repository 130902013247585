import st from "../../raffle.module.scss";
import Image from "next/image";
import mainBackgroundBanner from "../../assets/main-background-texture.jpg";
import BackgroundTree from "../../assets/background-tree";
import SnowingBackground from "./snowing-background";

export default function MainBannerBackground() {
  return (
    <div className={st["main-banner-background-container"]}>
      <div className={st["background-tree-container"]}>
        <BackgroundTree />
      </div>
      <Image
        src={mainBackgroundBanner}
        quality={100}
        style={{ objectFit: "cover" }}
        alt="main-banner-background"
      />
      <SnowingBackground snowflakeCount={15} />
    </div>
  );
}
