import React, { useRef, useCallback, useEffect, useMemo } from "react";
import DropdownArrow from "@assets/icons/general/DropdownArrow";
import gsap from "gsap";
import {
  classnames,
  mergeClasses,
  useControllableState,
} from "@lib/tools/helpers";

import st from "./index.module.scss";
import { useGSAP } from "@gsap/react";

/**
 * Accordion component that allows collapsing and expanding its content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.open=null] - Whether the accordion is open or closed. If `null`, the accordion is uncontrolled.
 * @param {boolean} [props.defaultOpen=false] - Whether the accordion is open by default when uncontrolled.
 * @param {function} [props.onChange=null] - Callback function triggered when the accordion is opened or closed.
 * @param {string} [props.headerColor=null] - The background color of the accordion header.
 * @param {string} [props.bodyColor=null] - The background color of the accordion body.
 * @param {ReactNode} props.header - The content of the accordion header.
 * @param {ReactNode} props.children - The content of the accordion body.
 * @param {boolean} [props.disabled] - Whether the accordion is disabled or not.
 * @param {boolean} [props.noArrow=false] - Whether to hide the arrow icon in the accordion header.
 * @param {import("react").CSSProperties} [props.style] - The gradient background of the accordion.
 * @param {string} [props.className] - Additional CSS classes to apply to the accordion.
 * @param {React.MutableRefObject} [props.stateRef=null] - A mutable ref object to store the state of the accordion.
 * @returns {JSX.Element} The rendered Accordion component.
 */
export default function Accordion({
  open = null,
  defaultOpen = false,
  onChange = null,
  headerColor = null,
  bodyColor = null,
  header,
  children,
  disabled,
  noArrow = false,
  stateRef = null,
  duration = 0.3,
  onClick,
  variant,
  className,
  style,
}) {
  const [isOpen, setIsOpen] = useControllableState(open, defaultOpen, onChange);
  const bodyRef = useRef();
  const variants = useMemo(() => variant?.split(" ") || [], [variant]);

  const handleAccordionClick = useCallback(() => {
    if (disabled) return;

    onClick?.(!isOpen);
    setIsOpen(!isOpen);
  }, [setIsOpen, disabled, onClick, isOpen]);

  // state useEffect
  useGSAP(
    () => {
      gsap.to(bodyRef.current, {
        height: isOpen ? "auto" : 0,
        opacity: isOpen ? 1 : 0,
        duration,
        ease: "sine",
      });
    },
    { dependencies: [isOpen] }
  );

  const setOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const setClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  useEffect(() => {
    if (!stateRef) return;
    stateRef.current = {
      isOpen,
      setOpen,
      setClose,
      toggle,
    };
  }, [stateRef, isOpen, setOpen, setClose, toggle]);

  const Header = useMemo(
    () => (typeof header === "function" ? header({ isOpen }) : header),
    [header, isOpen]
  );

  const Body = useMemo(
    () => (typeof children === "function" ? children({ isOpen }) : children),
    [children, isOpen]
  );

  return (
    <div
      {...mergeClasses(
        classnames(st, "container", isOpen && "open", ...variants),
        className
      )}
      style={style}
    >
      <div
        {...classnames(st, "head")}
        onClick={handleAccordionClick}
        style={{
          background: headerColor,
          cursor: disabled ? "no-drop" : "pointer",
        }}
      >
        {Header}

        {!disabled && !noArrow && (
          <DropdownArrow
            style={{
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            }}
          />
        )}
      </div>

      <div
        {...classnames(st, "body", isOpen && "open")}
        ref={bodyRef}
        style={{
          background: bodyColor,
        }}
      >
        <div>{Body}</div>
      </div>
    </div>
  );
}
