const SnowFlake = (props) =>
  `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="35"
    fill="none"
    viewBox="0 0 37 35"
  >
    <g fill="#fff" clipPath="url(#clip0_20129_122990)">
      <path d="m22.828 11.093 1.17.676-9.257 13.597-1.17-.676z"></path>
      <path d="m14.145 11.496 1.238-.65 8 14.056-1.238.651z"></path>
      <path d="m10.179 17.303 17.217.514-.097 1.311-17.217-.515z"></path>
      <path d="m18.528 10.343 1.396.002-.687 16.588-1.396-.001z"></path>
      <path d="m10.007 21.672 15.91-7.788.61 1.158-15.912 7.787z"></path>
      <path d="m11.563 13.273 15.203 8.787L26 23.184l-15.203-8.788z"></path>
      <path d="M20.071 11.535c-.306.45-.983.606-1.451.336a.95.95 0 0 1-.303-1.35c.306-.45.983-.606 1.45-.336.468.27.61.9.304 1.35m6.891 11.42c-.305.45-.983.607-1.45.336a.95.95 0 0 1-.304-1.35c.306-.449.983-.606 1.451-.335s.61.9.303 1.35m-14.618-8.449c-.306.45-.983.606-1.451.336a.95.95 0 0 1-.304-1.35c.306-.45.984-.606 1.451-.336s.61.9.304 1.35m7.094 12.321c-.306.45-.984.606-1.451.336a.953.953 0 0 1-.304-1.35c.306-.45.984-.606 1.451-.336s.61.9.304 1.35m7.065-11.574c-.306.45-.983.606-1.451.336a.953.953 0 0 1-.303-1.35c.306-.45.983-.607 1.45-.336.468.27.61.9.304 1.35m-14.667 7.18c-.306.45-.984.607-1.451.336a.95.95 0 0 1-.304-1.35c.306-.45.984-.606 1.451-.336.469.271.61.901.304 1.35M4.525 17.12l6.414.176-.093 1.31-6.415-.177zM27.074 17.807l6.065.17-.095 1.31-6.065-.17z"></path>
      <path d="m29.885 22.608-4.188-4.143 4.596-3.998.882.98-3.442 3.02 3.17 3.242zm-22.631-.632-.882-.98 3.442-3.02-3.17-3.242 1.02-.899 4.187 4.142zM25.84 6.646l1.17.676-3.443 5.057-1.17-.676z"></path>
      <path d="m22.487 12.772-1.35-5.556 1.353-.314 1.068 4.297 4.663-.984.284 1.26zm-8.502 11.292 1.169.676-3.443 5.057-1.169-.676z"></path>
      <path d="m15.059 29.546-1.068-4.297-4.664.984-.283-1.259 6.018-1.298 1.35 5.557zm9.719.685-.335-.585a131 131 0 0 1-2.386-4.276l-.335-.585 1.235-.618.335.586a132 132 0 0 1 2.386 4.275l.335.586z"></path>
      <path d="m20.12 29.413 1.906-5.553 5.87 1.671-.434 1.237-4.547-1.22-1.454 4.17zM11.528 6.871l1.238-.65 3.011 5.292-1.238.651z"></path>
      <path d="m9.66 10.922.435-1.236 4.547 1.22 1.454-4.171 1.34.305-1.905 5.553z"></path>
    </g>
    <defs>
      <clipPath id="clip0_20129_122990">
        <path
          fill="#fff"
          d="M15.664.78 36.65 12.91 21.708 34.857.723 22.727z"
        ></path>
      </clipPath>
    </defs>
  </svg>`;

export default SnowFlake;
